import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideSnackbar } from "../store/snackBar";

const SnackBar = () => {
  const snackDetails = useSelector((state) => state.snackBar);
  const dispatch = useDispatch();
  const { show, variant, message, duration } = snackDetails;
  const closeSnackBar = () => {
    dispatch(hideSnackbar());
  };
  return (
    <Snackbar
      open={show}
      autoHideDuration={duration || 4000}
      onClose={closeSnackBar}
    >
      {variant ? (
        <Alert
          onClose={closeSnackBar}
          severity={variant}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {message}
        </Alert>
      ):(
        <div></div>
      )}
    </Snackbar>
  );
};

export default SnackBar;
