import {createSlice} from '@reduxjs/toolkit';
export const loginSlice = createSlice({
    name :'Login',
    initialState : {
        isLogged : false,
        key : '',
        role:'',
        isAdmin:false,
        me  : {}
    },
    reducers : {
        loggin:(state,action)=>{
            state.isLogged = true;
            state.key = action.payload.accessToken;
            state.me = action.payload;
            // state.role = action.payload.user.data.me.role
            state.isAdmin = action.payload?.isAdmin
        },
        logout : (state)=>{
            state.isLogged = false;
            state.key = '';
            state.me = {};
            // state.role = action.payload.user.data.me.role
            state.isAdmin = false
        }
    }
})
export const {loggin,logout} = loginSlice.actions;
export default loginSlice.reducer;